import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, reactive, onMounted, computed } from 'vue';
selectOrganization;
import { selectOrganization } from '@/api/personnel';
import Cookies from "js-cookie";
import { useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const formRef = ref();
    const formState = reactive({
      userName: '',
      workNo: ''
    }); //查询

    const onSubmit = () => {
      if (!formState.workNo && !formState.userName) {
        if (isCompanyAdmin.value == 0) {
          getTableData(Cookies.get('workNo'), Cookies.get('userName'));
        } else {
          getTableData(Cookies.get('workNo'), Cookies.get('nickName'));
        }
      } else {
        getTableData(formState.workNo, formState.userName);
      }
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      getTableData(Cookies.get('workNo'), Cookies.get('userName'));
    }; //架构图


    const chartData = ref([]);

    const onNodeClick = (e, data) => {
      // console.log(e,data)
      getTableData(data.id, data.label);
    };

    const loading = ref(false);

    const getTableData = async (workNo, userName) => {
      const param = {
        workNo,
        userName
      };
      let res = await selectOrganization(param);

      if (res.code === 200) {
        chartData.value = [];

        if (res.data && res.data.length) {
          chartData.value = setTreeData(res.data);
        }

        loading.value = false;
      } else {
        _message.error(res.message);

        loading.value = false;
      }
    };

    const setTreeData = arr => {
      let treeData = [];
      arr.forEach(item => {
        let obj = {
          id: item.workNo,
          label: item.userName
        };

        if (item.current) {
          Object.assign(personnelData, item);
          obj.style = {
            "color": "#fff",
            "background": "#108ffe"
          };
        }

        if (item.childrenList && item.childrenList.length) {
          obj.children = setTreeData(item.childrenList);
        }

        treeData.push(obj);
      });
      return treeData;
    }; //人员信息


    const personnelData = reactive({
      userName: null,
      departmentName: null,
      workPlaceName: null,
      sex: null,
      jobName: null,
      userMobile: null,
      workNo: null,
      companyEmail: null,
      seatNo: null
    });
    onMounted(() => {
      if (isCompanyAdmin.value == 0) {
        getTableData(Cookies.get('workNo'), Cookies.get('userName'));
      } else {
        getTableData(Cookies.get('workNo'), Cookies.get('nickName'));
      }
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      chartData,
      onNodeClick,
      personnelData,
      loading
    };
  }

};